html,
body,
#root {
    margin: 0 auto;
    padding: 0;
    height: auto;
    width: auto;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #f5f5f5;
}
html {
    background-color: #ccc;
}
body,
html {
    background: #f5f5f5;
}

div,
p,
h1,
h2,
h3,
h4,
span,
button,
a {
    font-family: "Open Sans", sans-serif !important;
}

#root [data-reactroot] {
    height: 100%;
}
#root {
    max-width: 1280px;
}

* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

::-moz-selection {
    background-color: #bbdefb;
    color: #111;
}

::selection {
    background-color: #bbdefb;
    color: #111;
}

@media screen and (min-device-width: 991px), (min-device-width: 300px) {
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(1, 1, 1, 0.2);
        overflow: hidden;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #555;
        border-radius: 5px;
    }
    .slick-next {
        /* right: 0.1%; */
        z-index: 20;
    }
    .slick-prev {
        /* left: 0.1%; */
        z-index: 20;
    }
    .slick-next::after {
        right: 0.1%;
        color: #000000;
    }
    .slick-prev::before {
        left: 0.1%;
        color: #000000;
    }
    .search .results {
        overflow-y: auto;
        max-height: 300px;
    }
    .ui.list .list > .item,
    .ui.list > .item,
    ol.ui.list li,
    ul.ui.list li {
        padding: 0.424286em 0 !important;
    }
}

.valign-wrapper {
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
        align-items: center;
    -webkit-align-items: center;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

a:active,
a:visited,
a:link,
a:hover {
    text-decoration: none;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.relativeDiv {
    position: relative;
}

.hide {
    display: none !important;
}

.ui.label,
textarea,
.ui.segment {
    border-radius: 5px !important;
}

i.inverted.circular.icon.twitter-blue {
    background-color: #00acee !important;
}

.blur-effect {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.Blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.brighten-effect {
    -webkit-filter: brightness(200%) blur(3px);
    filter: brightness(200%) blur(3px);
}

.Brighten {
    -webkit-filter: brightness(200%) blur(3px);
    filter: brightness(200%) blur(3px);
}

.contrast-effect {
    -webkit-filter: contrast(200%) blur(3px);
    filter: contrast(200%) blur(3px);
}

.Contrast {
    -webkit-filter: contrast(200%) blur(3px);
    filter: contrast(200%) blur(3px);
}

.greyscale-effect {
    -webkit-filter: contrast(100%) blur(3px);
    filter: contrast(100%) blur(3px);
}

.Greyscale {
    -webkit-filter: grayscale(100%) blur(3px);
    filter: grayscale(100%) blur(3px);
}

.rotate-hue-effect {
    -webkit-filter: hue-rotate(90deg);
    filter: hue-rotate(90deg);
}

.Rotate-Hue {
    -webkit-filter: hue-rotate(90deg);
    filter: hue-rotate(90deg);
}

.invert-effect {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.Invert {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.saturate-effect {
    -webkit-filter: saturate(10) blur(3px);
    filter: saturate(10) blur(3px);
}

.Saturate {
    -webkit-filter: saturate(10) blur(3px);
    filter: saturate(10) blur(3px);
}

.sepia-effect {
    -webkit-filter: sepia(100%) blur(3px);
    filter: sepia(100%) blur(3px);
}

.Sepia {
    -webkit-filter: sepia(100%) blur(3px);
    filter: sepia(100%) blur(3px);
}

.ui.progress .bar {
    border-radius: 5px !important;
}

.CircularProgressbar {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
    width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    -o-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #ccc;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
}
.ui.card > .image > img,
.ui.cards > .card > .image > img {
    max-height: 176px;
    min-height: 176px;
}
.CircularProgressbar .CircularProgressbar-background {
    fill: transparent;
}

.full-video-main iframe {
    height: 100%;
    width: 250%;
    left: 50% !important;
    position: absolute;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}

.animated_list .basic {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.95) !important;
    font-size: 0.9em;
}

.animated_list .basic::after {
    color: black !important;
}

.animated_list .basic.inverted {
    border-radius: 5px;
    background: rgba(1, 1, 1, 0.95) !important;
    color: #fff !important;
    font-size: 0.9em;
}

.animated_list .disced {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.95) !important;
    color: black !important;
    font-size: 0.9em;
}

.animated_list .disced::after {
    color: black !important;
}

.animated_list .disced.inverted {
    border-radius: 50%;
    background: rgba(1, 1, 1, 0.95) !important;
    color: #fff !important;
    font-size: 0.9em;
}

.animated_list .disced.inverted::after {
    color: #fff !important;
}

.ui.fluid.search input {
    width: 100vw;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-width: 500px;
    min-width: 100px;
}

.ui.fluid.search input.prompt {
    width: 100vw;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

@media screen and (orientation: landscape) {
    .ui.fluid.search input {
        border-top-right-radius: 5px !important;
        border-top-left-radius: 5px !important;
    }
}

@media screen and (max-width: 900px) {
    .ui.fluid.search input.prompt {
        width: 100vw;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.leaderboard .slick-initialized .slick-next {
    right: 5px !important;
    z-index: 1;
    top: 15%;
}

.leaderboard .slick-initialized .slick-prev {
    left: 5px !important;
    top: 15%;
    z-index: 1;
}

.leaderboard .slick-initialized .slick-next:before {
    color: #000 !important;
    z-index: 1;
}

.leaderboard .slick-initialized .slick-prev:before {
    color: #000 !important;
    z-index: 1;
}

.leaderboard .progressBar .bar {
    background: -webkit-gradient(linear, left top, right top, from(red), to(yellow)) !important;
    background: -webkit-linear-gradient(left, red, yellow) !important;
    background: -o-linear-gradient(left, red, yellow) !important;
    background: linear-gradient(to right, red, yellow) !important;
}

#tree-root-container ul {
    padding: 10px !important;
    overflow-y: auto;
}

#tree-root-container ul li {
    padding: 5px !important;
}

#tree-root-container ul li div[data-radium] {
    background: transparent !important;
    color: #fff !important;
}

.ui.form .whitelabel.field > label {
    color: #fff;
}

.ReactPDF__Page {
    margin-bottom: 2px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background: #454545;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffc900;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ffc900;
    cursor: pointer;
}

.css-f91fgu {
    background: #ffffff !important;
    font-size: 16px !important;
    line-height: 18px !important;
}

li.css-79elbk > div {
    background: #ffffff !important;
    padding: 2px 0 !important;
}
li.css-79elbk > div > div {
    color: #666666 !important;
}
.vimeo-embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
}
.vimeo-embed-container iframe,
.vimeo-embed-container object,
.vimeo-embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vimeo .video-embed {
    width: 100% !important;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

input:focus::-webkit-input-placeholder {
    color: transparent !important;
}

input:focus::-moz-placeholder {
    color: transparent !important;
}

input:focus:-ms-input-placeholder {
    color: transparent !important;
}

input:focus::placeholder {
    color: transparent !important;
}
.slick-track {
    margin: 0px !important;
}
.slick-next::before {
    color: #000000;
}
.blog-article img {
    max-width: 100% !important;
}
.normalStyle {
    display: "block";
    height: "100%";
    overflow: "hidden";
}
.rotated {
    position: absolute;
    top: 22vh;
    left: 22vh;
    width: 100vw;
    height: 100vh;
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
@media screen and (min-width: 411px) and (min-device-height: 823px) {
    .rotated {
        position: absolute;
        top: 28vh;
        left: 22vh;
        width: 100vw;
        height: 94vh;
        -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
}
@media screen and (min-width: 375px) and (max-width: 428px) and (min-device-height: 812px) and (max-device-height: 926px) {
    .rotated {
        position: absolute;
        top: 32vh;
        left: 22vh;
        width: 100vw;
        height: 90vh;
        -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
    }
}

.black-transparent-overlay {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+60,1+84 */ /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 60%,
        rgba(0, 0, 0, 1) 84%,
        rgba(0, 0, 0, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(
        linear,
        left top, right top,
        from(rgba(0, 0, 0, 0)),
        color-stop(60%, rgba(0, 0, 0, 0.65)),
        color-stop(84%, rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 1))
    );
    background: -o-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 60%,
        rgba(0, 0, 0, 1) 84%,
        rgba(0, 0, 0, 1) 100%
    );
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 60%,
        rgba(0, 0, 0, 1) 84%,
        rgba(0, 0, 0, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
}
@media screen and (max-width: 900px) {
    .black-transparent-overlay { /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 60%,
            rgba(0, 0, 0, 1) 84%,
            rgba(0, 0, 0, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgba(0, 0, 0, 0)),
            color-stop(53%, rgba(0, 0, 0, 0.65)),
            color-stop(84%, rgba(0, 0, 0, 1)),
            to(rgba(0, 0, 0, 1))
        );
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 53%,
            rgba(0, 0, 0, 1) 84%,
            rgba(0, 0, 0, 1) 100%
        );
        background: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 53%,
            rgba(0, 0, 0, 1) 84%,
            rgba(0, 0, 0, 1) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 53%,
            rgba(0, 0, 0, 1) 84%,
            rgba(0, 0, 0, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
    }
}
.ui.dropdown .dropdownMenu{
    display: inherit;
}
div #dropdown-menu{
   left:115%;
}
div #all-menu-Item{
    padding:10px 20px 20px 20px!important;

}
div #menu-Item {
    padding:10px 20px 10px 20px!important;
}

.activity-table.ReactTable .-pagination .-previous,
.activity-table.ReactTable .-pagination .-next {
    margin: auto;
    height: 3em;
}